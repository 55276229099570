$borderlands-yellow: #FCF700;

.Title {
  grid-area: title;
}

.Label {
  grid-area: label;
  text-align: right;
}

.Textbox {
  grid-area: textbox;
  display: flex;
  flex-direction: column;
  align-items: left;

  
  input {
    width: 20%;
  }
}

.Output {
  grid-area: output;

  span {
    border: 1px solid;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
  span:hover {
    background-color: $borderlands-yellow;
    cursor: pointer;
  }
}

.List {
  grid-area: list;
  display: flex;
  flex-direction: column;
  align-items: left;

  span {
    border: 1px solid;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    width: 200px;
    background-color: $borderlands-yellow;
    margin-bottom: 5px;
  }
  span:hover {
    cursor: pointer;
  }
}

.Borderlands-View {
  display: grid;
  width: 100%;
  height: 100%;
}

@media only screen and (orientation: landscape) {
  .Borderlands-View {
    grid-template-columns: 25px 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-template-rows: 25px 50px auto;
    grid-template-areas:
      ". . . . ."
      ". title title title title"
      ". label textbox output list";
  }
}

@media only screen and (orientation: portrait) {
  .Borderlands-View {
    grid-template-columns: 75px 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-template-rows: 25px 50px 200px auto;
    grid-template-areas:
      ". . . ."
      ". title title title"
      ". label textbox output"
      ". list list list";
  }
}
