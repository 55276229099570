/* https://css-tricks.com/snippets/css/complete-guide-grid/ */

.Header {
  grid-area: header;
  background-color: #1976d2;
}

.Body {
  grid-area: body;
}

.SideBar {
  grid-area: sidebar;

  border-right: 1px solid;
  border-color: rgba(0, 0, 0, 0.12);

  ul {
    padding-top: 0;

    svg {
      margin-right: 16px;
    }
  }
}

.Footer {
  grid-area: footer;
}

.Main {
  display: grid;
  width: 100vw;
  height: 100vh;
}

@media only screen and (orientation: landscape) {
  .Main {
    grid-template-columns: 150px auto auto auto;
    grid-template-rows: 50px auto 50px;
    grid-template-areas:
      "header header header header"
      "sidebar body body body"
      "sidebar footer footer footer";
  }
}

@media only screen and (orientation: portrait) {
  .Main {
    grid-template-columns: 50px auto;
    grid-template-rows: 50px auto 50px;
    grid-template-areas:
      // "sidebar header"
      "header header"
      "sidebar body"
      "footer footer";
  }
}
